@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import "../../node_modules/bootstrap/scss/bootstrap";


body {
  //font-family: 'Roboto', sans-serif;
  //color: $font-color;
  //font-weight: 300;
  margin-top: 6rem;
}

img {
  max-width: 100%;
}

ul {
  list-style: square;
}

.bold {
  font-weight: 500;
}


.slick {
  &-dots {
    bottom: 10px;
    left: 0;

    li {

      width: 30px;
      height: 4px;
      margin: 0 4px;
      opacity: .2;


      button {
        border-radius: 0;

        width: inherit;
        height: inherit;
        background: $dark;
        padding: 0;
        margin: 0;

        &:before {
          content: unset;
        }
      }


      &.slick-active {
        opacity: 1;
      }
    }
  }

  &-arrow {
    &.slick-disabled {
      display: none !important;
    }
  }


}

nav {
  padding: 20px 0;

  i {
    font-size: 20px;
  }

  .navbar-brand {
    a {
      color: $gray;
    }
  }
}

.tool {
  &-card {
    padding: 40px 20px;
    margin: 40px 0 0 40px;
    border-radius: 4px;
    border: 2px solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $body-color;
    text-decoration: none;
    position: relative;
    text-align: center;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    height: 400px;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      transform: translateY(-10px);
      text-decoration: none;
      color: $body-color;
    }

    &-logo {
      position: absolute;

      left: -40px;
      top: -40px;
      height: 100px;
      width: 100px;

      img {
        max-width: 100%;
        max-height: 100%;
        -webkit-filter: drop-shadow(-2px 2px 2px #424242);
        filter: drop-shadow(-2px 2px 2px #424242);
      }

    }


    &-title {
      //text-transform: uppercase;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.2;
    }

    &-headline {
      //text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
    }

    &-description {
      margin-top: 30px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &-discover {
      text-transform: uppercase;
      margin-top: 30px;
      font-size: 14px;
      font-weight: 500;
    }


  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 60px;

    &-wrapper {
      h2 {
        text-align: center;
        margin: 80px 0 10px;
      }

      &:first-child h2 {
        margin-top: 40px;
      }
    }


    .wrapper {

      display: flex;
      flex: 0 0 auto;
      padding: 10px 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 10px;
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 100%;
        width: 430px;

      }

    }
  }

  &-page {

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }

    &-prevNext {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        max-width: 35px;
        margin: 5px;
        transition: all .2s;
      }

      .prev,
      .next {
        a:before {
          padding: 6px;
        }

        a {
          display: flex;
          align-items: center;
          color: $dark;

          &:hover {
            text-decoration: none;

            img {
              transform: scale(1.2);
            }
          }


        }
      }

      .prev a {
        &:before {
          content: "<";
        }
      }

      .next {
        margin-left: auto;

        a {
          flex-direction: row-reverse;

          &:before {
            content: ">";
          }
        }
      }
    }

    &-sideNav {
      position: fixed;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      text-align: right;
      text-transform: uppercase;
      font-size: 12px;

      @include media-breakpoint-up(sm) {
        right: 10px;
      }

      .sideNav-section-link {
        display: block;
        margin: 10px 0;
        cursor: pointer;
        position: relative;

        &:not(.active) {
          .sideNav-section-dot {
            background: unset !important;
          }
        }

        &:hover {
          .sideNav-section-title {
            display: inline-block;
          }
        }
      }

      .sideNav-section-title {
        display: none;
        padding: 2px 4px;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        //color: white;
      }

      .sideNav-section-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid;
        margin-left: 10px;
        display: none;
        @include media-breakpoint-up(sm) {
          display: inline-block;
        }
      }

    }

    &-header {
      text-align: center;
    }

    &-logo {

      margin: auto;
      max-width: 100px;
      @include media-breakpoint-up(sm) {
        max-width: 150px;
      }
    }

    &-title {
      //text-transform: uppercase;
      font-weight: 500;

      h1 {

        @include media-breakpoint-up(sm) {
          font-size: 60px;
        }
      }
    }

    &-headline {
      //text-transform: uppercase;

      font-weight: 400;
      margin-top: -10px;
      font-size: 18px;
      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }
    }

    &-toolLink {
      font-weight: 400;
      font-size: 18px;
    }

    &-betaToolLink {

      margin-top: 10px;

      .tool-page-links > div:not(:last-child):after {
        content: "";
      }

      a {
        font-weight: 300;
        font-size: 11px;
        text-transform: uppercase;
        background: $dark;
        padding: 2px 8px;
        color: white;
        display: inline-block;


      }

      svg {
        margin-left: 6px;
        vertical-align: top;
      }

      a:hover {
        text-decoration: none;
      }
    }

    &-links {
      & > div {
        display: inline-block;

        &:not(:last-child):after {
          content: "|";
          padding: 0 5px;
          color: $gray;
        }
      }
    }

    &-description {
      padding: 30px 0;
      margin-top: 50px;
      background: $gray;
      font-size: 20px;

      @include media-breakpoint-up(sm) {
        padding: 50px 0;
      }

      p:last-child {
        margin-bottom: 0;

      }
    }

    &-screenshots {
      margin: 20px 0 5px;

      img {
        padding: 5px;

      }

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
        margin: 50px -5px 0;
      }
    }

    &-io {
      margin-top: 40px;
      font-size: 90%;

      @include media-breakpoint-up(md) {
        margin-top: 60px;
      }

      strong {
        text-transform: uppercase;
        font-size: 95%;
      }
    }

    &-team {
      border-top: 1px solid #afb3c3;
      padding-top: 20px;
      font-size: 80%;
      height: 100%;
      margin-top: 30px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-top: 0;
        padding-left: 20px;
        margin-left: 20px;
        border-top: unset;
        border-left: 1px solid #afb3c3;
      }

      ul {
        padding-left: 30px;
      }
    }

    &-publications {
      max-width: 900px;
      margin: 60px auto 90px;
      @include media-breakpoint-up(sm) {
        margin: 80px auto 100px;
        width: 80%;
      }

      & > div {
        margin-bottom: 30px;
      }


      &-about {
        font-weight: 500;
        font-size: 18px;
      }

      ul > li {
        margin-bottom: 16px;
      }

      .external-link {
        margin-left: 10px;
      }

    }

    &-how {
      margin-top: 60px;

      .slider-wrapper {

        margin: 0 auto;
        max-width: 1024px;

        @include media-breakpoint-up(sm) {
          padding: 30px;
        }
        @include media-breakpoint-up(xl) {
          margin: 0 auto;
        }

        .slick-slider {
          padding: 30px 30px 60px;
          background: $gray;
          @include media-breakpoint-up(sm) {
            padding: 30px 50px 70px;
          }
        }

      }

      .how-stepName {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 10px;

      }

      .video-caption {
        text-align: center;
        font-size: 14px;
      }

      ul {
        padding-left: 25px;
      }
    }

    &-awards {
      margin-top: 60px;
      background: $gray;
      padding: 40px 0;

      @include media-breakpoint-up(sm) {
        margin-top: 90px;
        padding: 50px 0;
      }

      .awards-wrapper {

        @include media-breakpoint-up(md) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .award {

        @include media-breakpoint-up(md) {
          width: calc((100% / 2) - 10px);
        }

        text-align: center;
        padding: 10px;
      }
    }

    &-repository {
      padding: 60px 0;
      text-align: center;

      .logo-mini {
        max-width: 20px;
        margin-right: 10px;
        color: #0747a6;

        &:hover {
          color: #0b72ff;
        }
      }
    }

  }

}

.notFound {
  font-weight: 600;
  font-size: 30px;
  padding: 60px;
  text-align: center;
}