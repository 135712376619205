@import "../../../css/variables";
@import "~bootstrap/scss/bootstrap-grid";

.switch {
  display: flex;
  flex-direction: column;
  margin: 30px 0 50px;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.option {
  display: inline-block;
  padding: 4px 16px;
  background: white;
  cursor: pointer;
  border: 1px solid $accent-color;
  color: $accent-color;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;


  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  @include media-breakpoint-up(sm) {
    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }

  &.active {
    background-color: $accent-color;
    color: white;
  }
}


