$font-family-sans-serif: 'Roboto', sans-serif;
$body-color: #343a40;
$accent-color: #676768;
$font-weight-base: 300;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$gray: #e2e4e7;
$dark: #313133;

//@import "../../node_modules/bootstrap/scss/bootstrap";